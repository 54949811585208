import React from 'react';
import {Navigate} from 'react-router-dom';

import {authRouteConfig} from './AuthRoutes';
import Error403 from '../../modules/errorPages/Error403';
import {errorPagesConfigs} from './ErrorPagesRoutes';
import {dashboardConfig} from './DashboardsRoutes';
import {rootConfig} from 'modules/root';
import {targetConfig} from 'modules/Main';
import {accountPagesConfigs} from './AccountRoutes';

export const authorizedStructure = (loginUrl) => {
  return {
    fallbackPath: loginUrl,
    unAuthorizedComponent: <Error403 />,
    routes: [
      ...targetConfig,
      ...rootConfig,
      ...dashboardConfig,
      ...accountPagesConfigs,
    ],
  };
};

export const unAuthorizedStructure = (initialUrl) => {
  return {
    fallbackPath: initialUrl,
    routes: authRouteConfig,
  };
};

export const anonymousStructure = (initialUrl) => {
  return {
    routes: errorPagesConfigs.concat([
      {
        path: '/',
        element: <Navigate to={initialUrl} />,
      },
      {
        path: '*',
        element: <Navigate to='/error-pages/error-404' />,
      },
    ]),
  };
};
