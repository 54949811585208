import React from 'react';
import {Triangle} from 'react-loader-spinner';
import styled from 'styled-components';

const StyledAppLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const AppLoader = () => {
  return (
    <StyledAppLoader>
      <Triangle
        visible={true}
        height='80'
        width='80'
        color='#34495E'
        ariaLabel='triangle-loading'
        wrapperStyle={{}}
        wrapperClass=''
      />
    </StyledAppLoader>
  );
};

export default AppLoader;
