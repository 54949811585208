import React from 'react';

import {BrowserRouter} from 'react-router-dom';
import AppContextProvider from '@mj/context/AppContextProvider';
import AppThemeProvider from '@mj/context/AppThemeProvider';
import AppLocaleProvider from '@mj/context/AppLocaleProvider';
import AppAuthProvider from '@mj/services/auth/JWTAuthProvider';
import AuthRoutes from '@mj/components/AuthRoutes';
import AppLayout from '@mj/core/AppLayout';
import '@mj/mockapi';
import {GlobalStyles} from '@mj/core/theme/GlobalStyle';
import {Normalize} from 'styled-normalize';
import './styles/index.css';

const App = () => (
  <AppContextProvider>
    <AppThemeProvider>
      <AppLocaleProvider>
        <BrowserRouter>
          <AppAuthProvider>
            <AuthRoutes>
              <GlobalStyles />
              <Normalize />
              <AppLayout />
            </AuthRoutes>
          </AppAuthProvider>
        </BrowserRouter>
      </AppLocaleProvider>
    </AppThemeProvider>
  </AppContextProvider>
);

export default App;
