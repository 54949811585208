import React from 'react';
import {useLayoutContext} from '@mj/context/AppContextProvider/LayoutContextProvider';
import {
  StyledFooterBtn,
  StyledFooterBtnView,
  StyledMainFooter,
} from './AppFooter.styled';
import {Link} from 'react-router-dom';

const AppFooter = () => {
  const {footer} = useLayoutContext();

  if (footer) {
    return (
      <StyledMainFooter>
        <p>
          Crafted with 🤍
          {/* {' '}
          <Link to={'https://hashdecodes.com'}>Hash Decodes</Link> */}
        </p>
        <StyledFooterBtnView>
          <StyledFooterBtn type='link' color='primary'>
            <p>Copyright @2024</p>
          </StyledFooterBtn>
        </StyledFooterBtnView>
      </StyledMainFooter>
    );
  }
  return null;
};

export default AppFooter;
