import enLang from './entries/en-US';
import trLang from './entries/tr_TR';

const AppLocale = {
  en: enLang,
  tr: trLang,
  // zh: zhLang,
  // ar: arLang,
  // it: itLang,
  // es: esLang,
  // fr: frLang,
};

export default AppLocale;
