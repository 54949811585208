import {notification} from 'antd';

import codeMessage from './codeMessage';

const successHandler = (response, success = true) => {
  if (!(response.data.result || response.data.data)) {
    const {status} = response;
    let errorText = 'No data found';

    if (status === 203) {
      errorText = 'Documents are empty';
    }

    response = {
      ...response,
      status: 404,
      url: null,
      data: {
        success: false,
        result: null,
      },
    };

    notification.config({
      duration: 5,
      placement: 'bottomLeft',
    });

    notification.warning({
      message: `Request ${status}`,
      description: errorText,
    });
  } else {
    const {data} = response;
    const message = data && data.message;
    const successText = message || codeMessage[response.status];
    if (success) {
      notification.config({
        duration: 5,
        placement: 'bottomLeft',
      });

      notification.success({
        message: `Request success`,
        description: successText,
        placement: 'bottomLeft',
      });
    }

    return data;
  }
};

export default successHandler;
