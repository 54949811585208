import React from 'react';
import PropTypes from 'prop-types';
import {useSidebarContext} from '@mj/context/AppContextProvider/SidebarContextProvider';
import {StyledAppLogo} from './index.styled';
import {useNavigate} from 'react-router-dom';

const AppLogo = ({hasSidebarColor}) => {
  const {sidebarColorSet} = useSidebarContext();
  const navigate = useNavigate();
  return (
    <StyledAppLogo>
      {hasSidebarColor && sidebarColorSet.mode === 'dark' ? (
        <img
          onClick={() => navigate('/')}
          src='/assets/images/logo-white-with-name.png'
          alt='hd-logo'
        />
      ) : (
        <img
          onClick={() => navigate('/')}
          src='/assets/images/logo-with-name.png'
          alt='hd-logo'
        />
      )}
    </StyledAppLogo>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
