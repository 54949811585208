import React, {createContext, useContext, useEffect, useState} from 'react';
import defaultConfig from '@mj/constants/defaultConfig';
import PropTypes from 'prop-types';

const LocaleContext = createContext();
const LocaleActionsContext = createContext();
export const useLocaleContext = () => useContext(LocaleContext);

export const useLocaleActionsContext = () => useContext(LocaleActionsContext);

const LocaleContextProvider = ({children}) => {
  const [locale, updateLocale] = useState(() => {
    const storedLocale = JSON.parse(localStorage.getItem('myLocale'));
    return storedLocale?.languageId ? storedLocale : defaultConfig.locale;
  });

  useEffect(() => {
    if (locale?.languageId)
      localStorage.setItem('myLocale', JSON.stringify(locale));
    console.log(locale);
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        rtlLocale: defaultConfig.rtlLocale,
      }}
    >
      <LocaleActionsContext.Provider
        value={{
          updateLocale,
        }}
      >
        {children}
      </LocaleActionsContext.Provider>
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;

LocaleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
