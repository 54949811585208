function mergePermissions(permissionsArray) {
  // Check if permissionsArray is an array and not empty
  if (!Array.isArray(permissionsArray) || permissionsArray.length === 0) {
    console.error('Invalid input: permissionsArray must be a non-empty array');
    return {};
  }

  const mergedPermissions = {};

  permissionsArray.forEach(({permissions: permissionObj}) => {
    // Check if permissionObj is an object

    if (typeof permissionObj !== 'object' || permissionObj === null) {
      console.error('Invalid input: permissionObj must be an object');
      return; // Skip invalid input
    }

    Object.keys(permissionObj).forEach((key) => {
      const permissions = permissionObj[key];
      // Check if permissions is an array
      if (Array.isArray(permissions)) {
        if (mergedPermissions[key]) {
          mergedPermissions[key] = Array.from(
            new Set([...mergedPermissions[key], ...permissions]),
          );
        } else {
          mergedPermissions[key] = [...permissions];
        }
      }
    });
  });

  // console.log('Merged permissions:', mergedPermissions);
  return mergedPermissions;
}

function getPermissionsByLocation(powers, currentLocation) {
  const matchingPower = powers?.find(
    (power) => power?.branchId?._id === currentLocation,
  );

  if (matchingPower) {
    return mergePermissions(matchingPower?.permissions);
  } else {
    return null; // No matching company found
  }
}

export const getUserFromJwtAuth = (user) => {
  if (user) {
    const {powers, ...rest} = user;
    return {
      ...rest,
      id: user?._id,
      uid: user?._id,
      displayName: user?.name,
      powers: user?.powers,
      power: user?.currLocation
        ? getPermissionsByLocation(powers, user?.currLocation?._id)
        : {},
      currCompany: user?.currLocation?._id,
    };
  }
  return user;
};
