import React from 'react';
import {RoutePermittedRole} from '@mj/constants/AppEnums';
import Account from '../../../modules/account/MyProfile';

export const accountPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/my-profile',
    element: <Account />,
  },
];
