import React, {useState} from 'react';
import {Button, Col, Form, Input, Select} from 'antd';
import AppRowContainer from '@mj/components/AppRowContainer';
import {useDropzone} from 'react-dropzone';
import {useAuthUser} from '@mj/hooks/AuthHooks';
import {
  StyledInfoUpload,
  StyledInfoUploadAvatar,
  StyledInfoUploadBtnView,
  StyledInfoUploadContent,
} from './index.styled';
import {StyledUserProfileGroupBtn} from '../index.styled';
import PhoneCodes from '@mj/custom/PhoneCodes';
import UploadNode from '@mj/custom/UploadNode';
import {normFile} from '@mj/custom/fns';
import FileUpload from '@mj/custom/FormFeilds/FileUpload';
import errorHandler from '@mj/services/auth/errorHandler';
import jwtAxios from '@mj/services/auth/jwt-auth';
import successHandler from '@mj/services/auth/successHandler';
import {useNavigate} from 'react-router-dom';

const PersonalInfo = () => {
  const {user} = useAuthUser();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const res = await jwtAxios.patch(`admin/update/${user?._id}`, values);
      successHandler(res);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      errorHandler(error);
      setLoading(false);
    }
  };

  return (
    <Form
      onFinish={onFinish}
      layout='vertical'
      initialValues={{
        ...user,
        userImage: user.photoURL
          ? user.photoURL
          : '/assets/images/placeholder.jpg',
      }}
    >
      <StyledInfoUpload>
        {/* <StyledInfoUploadAvatar src={userImage} /> */}

        <StyledInfoUploadContent>
          <StyledInfoUploadBtnView>
            <Form.Item
              name='picture'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <FileUpload folder={'profiles'} name='Picture'></FileUpload>
            </Form.Item>
            {/* <Button onClick={onReset}>Reset</Button> */}
          </StyledInfoUploadBtnView>
          {/* <p>Allowed JPG, GIF or PNG. Max size of 800kB</p> */}
        </StyledInfoUploadContent>
      </StyledInfoUpload>
      <AppRowContainer gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='displayName'
            label='First Name'
            rules={[{required: true, message: 'Please input your Full Name!'}]}
          >
            <Input placeholder='Full Name' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='lastName'
            label='Last Name'
            rules={[{required: true, message: 'Please input your User Name!'}]}
          >
            <Input placeholder='Last Name' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='email'
            label='Email'
            rules={[
              {required: true, message: 'Please input your e-mail address!'},
            ]}
          >
            <Input type='text' placeholder='E-mail' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label='Phone' name='phone'>
            <Input type='text' placeholder='Phone' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='whatsappCode'
            label='Country Code'
            initialValue={'+92'}
            rules={[
              {
                required: true,
                message: 'Please select a country code!',
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Select a country code'
              // disabled={isProfile}
              options={PhoneCodes?.map((p) => ({
                label: `${p.code} ${p.dial_code}`,
                value: p.dial_code,
              }))}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='whatsappNumber'
            label='Whatsapp Number'
            rules={[
              {
                pattern: /^[\d()-]+$/,
                message: 'Strings are not allowed!',
              },
              {
                required: true,
                message: 'Please input a valid number!',
              },
            ]}
          >
            <Input placeholder='Enter number here' />
          </Form.Item>
        </Col>
        <Col xs={24} md={24}>
          <StyledUserProfileGroupBtn
            shouldUpdate
            className='user-profile-group-btn'
          >
            <Button type='primary' htmlType='submit' loading={loading}>
              Save Changes
            </Button>
            <Button htmlType='cancel'>Cancel</Button>
          </StyledUserProfileGroupBtn>
        </Col>
      </AppRowContainer>
    </Form>
  );
};

export default PersonalInfo;
