import React from 'react';
import PropTypes from 'prop-types';
import {useSidebarContext} from '@mj/context/AppContextProvider/SidebarContextProvider';
import {StyledAppLogo} from './index.styled';

const AppLogoSignIn = ({hasSidebarColor}) => {
  const {sidebarColorSet} = useSidebarContext();
  return (
    <StyledAppLogo>
      {hasSidebarColor && sidebarColorSet.mode === 'dark' ? (
        <img src='/assets/images/logo-white-with-name.png' alt='crema-logo' />
      ) : (
        <img src='/assets/images/logo-with-name.png' alt='crema-logo' />
      )}
    </StyledAppLogo>
  );
};

export default AppLogoSignIn;

AppLogoSignIn.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
