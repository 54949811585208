import {RoutePermittedRole} from '@mj/constants/AppEnums';
import React from 'react';
const Donation = React.lazy(() => import('./Categories'));
const DonationForm = React.lazy(() => import('./Categories/MainForm'));
const Playlist = React.lazy(() => import('./Playlist'));
const PlaylistForm = React.lazy(() => import('./Playlist/MainForm'));
const Videos = React.lazy(() => import('./Videos'));
const VideosForm = React.lazy(() => import('./Videos/VideoForm'));
const PlansForm = React.lazy(() => import('./Plans/CreatePlanDrawer'));
const Plans = React.lazy(() => import('./Plans'));
const Subscription = React.lazy(() => import('./Subscriptions'));
const QuizForm = React.lazy(() => import('./Quizzes/QuizForm'));
const Quiz = React.lazy(() => import('./Quizzes'));
const Notification = React.lazy(() => import('./Notification'));
const Calendar = React.lazy(() => import('./Calendar'));
const ReportProbelms = React.lazy(() => import('./Reporting'));
const Transactions = React.lazy(() => import('./Transactions'));
export const targetConfig = [
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/ReportProblems'],
    element: <ReportProbelms />,
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/transactions'],
    element: <Transactions />,
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/Plans'],
    element: <Plans />,
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/Calendar'],
    element: <Calendar />,
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/Notification'],
    element: <Notification />,
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/Subscription', '/Subscription/workspace/:id'],
    element: <Subscription />,
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/chapters'],
    element: <Donation />,
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/chapters/workspace', '/chapters/workspace/:id'],
    element: <DonationForm />,
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/subchapter'],
    element: <Playlist />,
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/subchapter/workspace', '/subchapter/workspace/:id'],
    element: <PlaylistForm />,
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/Videos'],
    element: <Videos />,
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/Videos/workspace', '/Videos/workspace/:id'],
    element: <VideosForm />,
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/Assessment'],
    element: <Quiz />,
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ['/Assessment/workspace', '/Assessment/workspace/:id'],
    element: <QuizForm />,
  },
];
