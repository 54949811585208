import React from 'react';
import {
  MdAssessment,
  MdOutlineContactPhone,
  MdOutlineContactSupport,
  MdOutlineManageAccounts,
  MdOutlineTextsms,
  MdOutlineWhatsapp,
  MdReportProblem,
  MdSubscriptions,
  MdTaskAlt,
} from 'react-icons/md';
import {
  BsBank,
  BsBriefcase,
  BsCashCoin,
  BsCurrencyBitcoin,
  BsCurrencyDollar,
  BsPeople,
  BsShieldLock,
} from 'react-icons/bs';
import {FiUsers} from 'react-icons/fi';
import {RoutePermittedRole} from '@mj/constants/AppEnums';
import {RiTodoLine, RiUserStarLine} from 'react-icons/ri';
import {
  FaDonate,
  FaMapMarkedAlt,
  FaSearchDollar,
  FaTasks,
} from 'react-icons/fa';
import {
  GiOrganigram,
  GiPayMoney,
  GiTakeMyMoney,
  GiWaterBottle,
} from 'react-icons/gi';
import {
  AiOutlineDashboard,
  AiOutlineReconciliation,
  AiOutlineUsergroupAdd,
} from 'react-icons/ai';
import {TbReportMoney, TbTargetArrow} from 'react-icons/tb';
import {HiOutlineClipboardList} from 'react-icons/hi';
import {BiCategory} from 'react-icons/bi';
import {
  CalendarOutlined,
  NotificationOutlined,
  TransactionOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import {CgPlayListAdd} from 'react-icons/cg';
import {FcPlanner} from 'react-icons/fc';
const routesConfig = [
  {
    id: 'dashboards',
    title: 'Application',
    // permittedRole: RoutePermittedRole.admin,
    messageId: 'sidebar.application',
    type: 'group',
    children: [
      {
        id: 'admin',
        title: 'admin',
        messageId: 'sidebar.dashboard',
        icon: <AiOutlineDashboard />,
        path: '/dashboards/admin',
      },
      {
        id: 'chapters',
        permittedRole: RoutePermittedRole.donation,
        title: 'chapters',
        messageId: 'sidebar.apps.categories',
        path: '/chapters',
        icon: <FaDonate />,
      },
      {
        id: 'subchapter',
        permittedRole: RoutePermittedRole.donation,
        title: 'subchapter',
        messageId: 'sidebar.apps.playlist',
        path: '/subchapter',
        icon: <CgPlayListAdd />,
      },
      {
        id: 'Videos',
        permittedRole: RoutePermittedRole.donation,
        title: 'Videos',
        messageId: 'sidebar.apps.videos',
        path: '/Videos',
        icon: <VideoCameraOutlined />,
      },
      {
        id: 'Assessment',
        permittedRole: RoutePermittedRole.donation,
        title: 'Assessment',
        messageId: 'sidebar.apps.Quiz',
        path: '/Assessment',
        icon: <MdAssessment />,
      },
      {
        id: 'Plans',
        permittedRole: RoutePermittedRole.donation,
        title: 'Plans',
        messageId: 'sidebar.apps.Plans',
        path: '/Plans',
        icon: <FcPlanner />,
      },
      {
        id: 'subscription',
        permittedRole: RoutePermittedRole.donation,
        title: 'subscription',
        messageId: 'sidebar.apps.subscription',
        path: '/subscription',
        icon: <MdSubscriptions />,
      },
      {
        id: 'transactions',
        permittedRole: RoutePermittedRole.donation,
        title: 'transactions',
        messageId: 'sidebar.apps.transactions',
        path: '/transactions',
        icon: <TransactionOutlined />,
      },
      {
        id: 'Notification',
        permittedRole: RoutePermittedRole.donation,
        title: 'Notification',
        messageId: 'sidebar.apps.Notification',
        path: '/Notification',
        icon: <NotificationOutlined />,
      },
      {
        id: 'Calendar',
        permittedRole: RoutePermittedRole.donation,
        title: 'Calendar',
        messageId: 'sidebar.apps.Calendar',
        path: '/Calendar',
        icon: <CalendarOutlined />,
      },
      {
        id: 'Reports',
        permittedRole: RoutePermittedRole.donation,
        title: 'reports',
        messageId: 'sidebar.apps.reportsProblem',
        path: '/ReportProblems',
        icon: <MdReportProblem />,
      },
    ],
  },
  {
    id: 'settings',
    title: 'Settings',
    messageId: 'sidebar.settings',
    permittedRole: RoutePermittedRole.employee,
    type: 'group',
    children: [
      // {
      //   id: 'permission',
      //   key: 130,
      //   permittedRole: RoutePermittedRole.permissions,
      //   title: 'Permission',
      //   messageId: 'sidebar.settings.premission',
      //   path: '/settings/permission',
      //   icon: <BsShieldLock />,
      // },
      // {
      //   id: 'company',
      //   key: 110,
      //   permittedRole: RoutePermittedRole.branch,
      //   title: 'branches',
      //   messageId: 'sidebar.settings.branch',
      //   path: '/settings/company',
      //   icon: <GiOrganigram />,
      // },
      {
        id: 'user',
        permittedRole: RoutePermittedRole.employee,
        title: 'employee',
        messageId: 'sidebar.settings.users',
        icon: <AiOutlineUsergroupAdd />,
        path: '/settings/user',
        power: [4],
      },
    ],
  },
];
export default routesConfig;
