const title = 'Chapters';
const dataRoute = 'erp/Categories';
const navPath = '/chapters';

export const dataInfo = {
  formTitle: title,
  LogTitle: 'Categories',
  tableTitle: title,
  metaData: title,
  dataRoute,
  navPath,
  title,
  permission: 'Categories', //user //admin // moduleName
};
