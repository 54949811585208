export const blogSidebar = {
  recentPost: [
    {
      id: 1,
      srcImg: '/assets/images/extra-pages/blog/blog-img.png',
      title: 'The standard Lorem Ipsum passage used?',
      duration: '12 December 2021',
    },
    {
      id: 2,
      srcImg: '/assets/images/extra-pages/blog/blog-img.png',
      title: 'The standard Lorem Ipsum passage used?',
      duration: '12 December 2021',
    },
    {
      id: 3,
      srcImg: '/assets/images/extra-pages/blog/blog-img.png',
      title: 'The standard Lorem Ipsum passage used?',
      duration: '12 December 2021',
    },
  ],
  categories: [
    {
      id: 1,
      title: 'Fashion',
      number: 10,
    },
    {
      id: 2,
      title: 'Business',
      number: 10,
    },
    {
      id: 3,
      title: 'Creative',
      number: 10,
    },
    {
      id: 4,
      title: 'Lifestyle',
      number: 10,
    },
  ],
  tag: [
    {
      id: 1,
      name: 'Fashion',
    },
    {
      id: 2,
      name: 'Development',
    },
    {
      id: 3,
      name: 'Lifestyle',
    },
    {
      id: 4,
      name: 'Hotel',
    },
    {
      id: 5,
      name: 'Event',
    },
    {
      id: 6,
      name: 'Multimedia',
    },
  ],
};
export const blogDetail = {
  blogDetailHeader: [
    {
      id: 1,
      icon: 'BiUserCircle',
      title: 'John Deuo',
    },
    {
      id: 2,
      icon: 'AiOutlineCalendar',
      title: '13 Mar 2021',
    },
    {
      id: 3,
      icon: 'BiCommentDetail',
      title: '100 Comments',
    },
  ],
  cardMediaAction: 'Concept',
  duration: '12 December 2021',
  blogDetailContent: {
    cardMedia: '/assets/images/extra-pages/blog/blog-img.png',
    title: 'You can never quit. Winners never quit, and quitters never win',
    description:
      'Tend to repeat predefined chunks as necessary, making this the first true generator on the internet. It uses dictionary the over 200 Latin words, combined with a handful of model sentence structures, to generate is lorem ipsum which looks reasonable. The generated Lorem ipsum is therefore always free from repetition, injected humour.',
    content: `<div>
      <div
        id="tooltip"
        style="
          border: 1px solid rgba(0, 0, 0, 0.12);
          border-left: 4px solid #2997ff;
          border-radius: 4px;
          padding: 25px;
          margin-bottom: 25px;
        "
      >
        <p
          style="
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            color: rgb(17, 24, 39);
            font-style: italic;
            margin-bottom: 20px;
          "
        >
          Reading is not only informed by what’s going on with us at that moment,
          but also governed by how our eyes and brains work to process information.
          What you see and what you’re experiencing as you read these words is quite
          different.
        </p>
        <h4 style="font-size: 14px; font-weight: 600; color: #2997ff; margin: 0">
          JOHN DEUO
        </h4>
      </div>
      <img
        src="/assets/images/extra-pages/blog/blog-detail-img.png"
        alt="Blog Image"
        style="float: left; margin-right: 30px; margin-bottom: 20px"
      />
      <div style="margin-bottom: 40px">
        <p
          style="
            margin: 0;
            font-weight: 400;
            line-height: 1.5;
            font-size: 14px;
            margin-bottom: 20px;
            color: rgb(107, 114, 128);
          "
        >
          Lorem ipsum is simply dummy printing typesetting industry. Lorem ipsum has
          been the industry’s standard dummy text ever since the 1500s, when an
          unknown printer took galley type and scrambled it to make a type specimen
          book. It has survived not only five centuries, but also the leap
          electronic typesetting, remaining essentially unchanged popularised in the
          1960s with the release of Letraset containing lorem passages, and more
          recently desktop publishing software like aldus pagemaker including
          versions lorem ipsum. There are many variations passages of Lorem ipsum
          available, majority have suffered alteration some form, by injected
          humour, or randomised wordspsum and generators internet type tend to
          repeat predefined chunks as necessary, making this the first true
          generator on the internet. It uses dictionary the over 200 Latin words,
          combined with a handful of model sentence structures, to generate is lorem
          ipsum which looks reasonable. The generated Lorem ipsum is therefore
          always free from repetition, injected humour.
        </p>
        <h5
          style="
            margin: 0;
            font-size: 14px;
            font-family: Be Vietnam, sans-serif;
            line-height: 1.5;
            color: rgb(17, 24, 39);
            font-weight: 600;
            margin-bottom: 10px;
          "
        >
          You can never quit. Winners never quit, and quitters never win
        </h5>
        <p
          style="
            margin: 0;
            font-weight: 400;
            line-height: 1.5;
            color: rgb(17, 24, 39);
            font-size: 14px;
            margin-bottom: 20px;
            color: rgb(107, 114, 128);
          "
        >
          Tend to repeat predefined chunks as necessary, making this the first true
          generator on the internet. It uses dictionary the over 200 Latin words,
          combined with a handful of model sentence structures, to generate is lorem
          ipsum which looks reasonable. The generated Lorem ipsum is therefore
          always free from repetition, injected humour.
        </p>
      </div>
    </div>
    `,
    tag: [1, 2, 3],
    likeCount: 5,
    post: {
      user: '/assets/images/avatar/A12.jpg',
      userName: 'John Deuo',
      userPosition: 'Co-founder',
      description:
        "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    meta: {
      metatitle: 'Meta title',
      metadesc: 'Meta Description',
      keywords: 'Meta, title',
    },
  },
  blogComment: [
    {
      id: 1,
      image: '/assets/images/avatar/A12.jpg',
      name: 'John Deuo',
      duration: '17 July 2020, 6:05 PM',
      comment:
        "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 2,
      image: '/assets/images/avatar/A12.jpg',
      name: 'John Deuo',
      duration: '17 July 2020, 6:05 PM',
      comment:
        "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 3,
      image: '/assets/images/avatar/A12.jpg',
      name: 'John Deuo',
      duration: '17 July 2020, 6:05 PM',
      comment:
        "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ],
};

export const blogContent = Array.from({length: 7}, (_, index) => index + 1).map(
  (item) => {
    return {...blogDetail, id: item};
  },
);
