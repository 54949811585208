import React from 'react';
import {RoutePermittedRole} from '@mj/constants/AppEnums';
import {Button, Result} from 'antd';
import {SmileOutlined} from '@ant-design/icons';

const Dashboard = React.lazy(() => import('modules/dashboards'));

export const dashboardConfig = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/admin',
    element: <Dashboard />,
  },
];
