import {RoutePermittedRole} from '@mj/constants/AppEnums';
import React from 'react';

const Employees = React.lazy(() => import('./Employee/Employee/List'));
const EmployeesCreate = React.lazy(() =>
  import('./Employee/Employee/CreateEmployee'),
);
const Role = React.lazy(() => import('./Employee/Role/CreateForm'));
const RoleList = React.lazy(() => import('./Employee/Role/List'));

export const rootConfig = [
  {
    permittedRole: RoutePermittedRole.permission,
    path: '/settings/permission',
    element: <RoleList />,
  },
  {
    permittedRole: RoutePermittedRole.permission,
    path: [
      '/settings/permission/workspace',
      '/settings/permission/workspace/:id',
    ],
    element: <Role />,
  },

  {
    permittedRole: RoutePermittedRole.employee,
    path: '/settings/user',
    element: <Employees />,
  },

  {
    permittedRole: RoutePermittedRole.admin,
    path: [
      '/settings/user/workspace',
      '/settings/user/workspace/:id',
      '/settings/user/profile/:id',
    ],
    element: <EmployeesCreate />,
  },
];
