// ForJWT Auth
import {getUserFromJwtAuth} from '@mj/helpers/AuthHelper';
import {useJWTAuth, useJWTAuthActions} from '@mj/services/auth/JWTAuthProvider';

export const useAuthUser = () => {
  const {user, isAuthenticated, isLoading} = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user: getUserFromJwtAuth(user),
  };
};

export const useAuthMethod = () => {
  const {signInUser, logout} = useJWTAuthActions();
  return {
    signInUser,
    logout,
  };
};
